<template>
  <v-toolbar-title class="flex-grow-1">
    <router-link
      :to="{ name: 'ShiftShow', params: { id: item.shift.event_id, shift_id: item.shift.id } }"
      class="flex flex-grow-1 align-center"
    >
      <div style="max-width: calc(100% - 30px); overflow: hidden; text-overflow: ellipsis;">
        <strong v-if="item.shift && item.shift.name">
          {{ item.shift.name }}
        </strong>
        <strong v-else>
          {{ item.shift.start_date | moment("dddd MMM D, YYYY") }}
        </strong>
        <strong v-if="item.shift && item.shift.event">
        - {{ item.shift.event.name }}
        </strong>
        <strong v-if="item.shift && item.shift.venue && item.shift.venue.state_text && item.shift.venue.city">
          - {{ item.shift.venue.city }},
          {{ item.shift.venue.state_text }}
        </strong>
      </div>

      <!-- All shift info -->
      <v-menu v-if="item.shift" :open-on-hover="true" offset-y class="tooltip-menu" max-width="99%">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="blue-grey" class="" style="position: absolute; top: 0; right: 10px;">
            mdi-information-outline
          </v-icon>
        </template>

        <div class="tooltip" style="white-space: nowrap;">
          <p v-if="item.shift.event && item.shift.event.name" class="event-name">
            <strong class="pr-1">Event Name:</strong>{{ item.shift.event.name }}
          </p>

          <p v-if="item.shift.name" class="shift-name">
            <strong class="pr-1">Shift Name:</strong>{{ item.shift.name }}
          </p>

          <p v-if="item.shift.start_date && item.shift.end_date && item.shift.start_date !== item.shift.end_date">
            <strong class="pr-1">Date:</strong>{{ item.shift.start_date | moment("dddd MMMM D, YYYY") }} - {{ item.shift.end_date | moment("dddd MMMM D, YYYY") }}
          </p>
          <p v-else-if="item.shift.start_date">
            <strong class="pr-1">Date:</strong>{{ item.shift.start_date | moment("dddd MMMM D, YYYY") }}
          </p>

          <p v-if="item.shift.venue && item.shift.venue.state_text && item.shift.venue.city">
            <strong class="pr-1">Location:</strong>{{ item.shift.venue.name }} - {{ item.shift.venue.city }}, {{ item.shift.venue.state_text }}
          </p>

          <p v-if="item.shift.artist && item.shift.artist.name">
            <strong class="pr-1">Artist:</strong>{{ item.shift.artist.name }}
          </p>

          <p v-if="item.shift.doors">
            <strong class="pr-1">Doors:</strong>{{ item.shift.doors }}
          </p>

          <p v-if="item.shift.check_in">
            <strong class="pr-1">Check-In:</strong>{{ item.shift.check_in }}
          </p>

          <p v-if="item.shift.volunteer_cap">
            <strong class="pr-1">Volunteer Cap:</strong>{{ item.shift.volunteer_cap }}
          </p>

          <p v-if="item.shift.total_volunteer_cap">
            <strong class="pr-1">Total Volunteer Cap:</strong>{{ item.shift.total_volunteer_cap }}
          </p>
        </div>
      </v-menu>
    </router-link>

    <div v-if="item.shift && item.shift.event && item.shift.event.teams" class="mt-2 d-flex flex-wrap">
      <v-menu :open-on-hover="true" :offset-y="true" class="tooltip-menu" max-width="99%">
        <template v-slot:activator="{ on }">
          <v-chip class="mr-2 mb-2" color="blue-grey" text-color="white" v-on="on">
            <v-avatar left>
              <v-icon>mdi-alarm</v-icon>
            </v-avatar>
            <strong class="pr-1">Schedule</strong>
          </v-chip>
        </template>
        <div class="tooltip">
          <span>
            <ul class="schedule-items-list">
              <li v-for="scheduleItem in item.shift.shift_schedules" :key="scheduleItem.id">
                <strong>{{ scheduleItem.start_date | moment("dddd MMM D, YYYY") }}</strong>
                <br />
                Doors: {{ scheduleItem.doors }} | Check:
                {{ scheduleItem.check_in }}
              </li>
            </ul>
          </span>
        </div>
      </v-menu>
      <v-chip v-if="item.shift.venue && item.shift.venue.name" class="mr-2 mb-2" color="blue-grey" text-color="white">
        <v-avatar left>
          <v-icon>mdi-home</v-icon>
        </v-avatar>
        {{ formatVenueName(item.shift.venue) }}
      </v-chip>
      <v-chip v-if="item.shift && item.shift.artist" class="mr-2 mb-2" color="blue-grey" text-color="white">
        <v-avatar left>
          <v-icon>mdi-account-music</v-icon>
        </v-avatar>
        {{ item.shift.artist.name }}
      </v-chip>
    </div>
    <div v-else class="mt-2 d-flex flex-wrap">
      <template>
        <v-chip class="mr-2 mb-2" color="blue-grey" text-color="white">
          <v-avatar left>
            <v-icon>mdi-alarm</v-icon>
          </v-avatar>
          <strong class="pr-1">Doors:</strong>
          {{ item.shift.doors }}
        </v-chip>
        <v-chip class="mr-2 mb-2" color="blue-grey" text-color="white">
          <v-avatar left>
            <v-icon>mdi-alarm</v-icon>
          </v-avatar>
          <strong class="pr-1">Check-In:</strong>
          {{ item.shift.check_in }}
        </v-chip>
        <v-chip
          v-if="item.shift && item.shift.venue && item.shift.venue.name"
          class="mr-2 mb-2"
          color="blue-grey"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-home</v-icon>
          </v-avatar>
          {{ formatVenueName(item.shift.venue) }}
        </v-chip>
        <v-chip
          v-if="item.shift.artist && item.shift.artist.name"
          class="mr-2 mb-2"
          color="blue-grey"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-account-music</v-icon>
          </v-avatar>
          {{ item.shift.artist.name }}
        </v-chip>
        <v-chip
          v-if="item.accepted || item.confirmed || item.declined || item.pending || item.attended"
          class="mr-2 mb-2"
          color="blue-grey"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-check</v-icon>
          </v-avatar>
          {{ formatStatus(item) }}
        </v-chip>
        <v-chip v-if="item.roll_call" class="mr-2 mb-2" color="blue-grey" text-color="white">
          <v-avatar left>
            <v-icon>mdi-script-text</v-icon>
          </v-avatar>
          {{ titleCase(item.roll_call) }}
        </v-chip>
      </template>
    </div>
  </v-toolbar-title>
</template>

<script>
import { titleCase } from '@/utils';

export default {
  name: 'VolunteerShiftToolbar',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    titleCase,
    formatStatus(volunteer_shift) {
      if (volunteer_shift.attended)
        return 'Attended';
      else if (volunteer_shift.accepted)
        return 'Accepted';
      else if (volunteer_shift.declined)
        return 'Declined';
      else if (volunteer_shift.confirmed)
        return 'Confirmed';
      else if (volunteer_shift.pending)
        return 'Pending';
      else if (volunteer_shift.waitlist)
        return 'Waitlisted'
      else return '';
    },
  }
}
</script>
