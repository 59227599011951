<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="6">
        <div class="list-header list-header--emails">
          <h4>Emails</h4>
        </div>
        <div v-if="emailListVisible">
          <div v-for="item in emails" :key="item.id">
            <v-toolbar flat class="search-result" style="margin-bottom: 10px">
              <v-toolbar-title class="flex-grow-1">
                <router-link
                  :to="{ name: 'EmailShow', params: { id: item.id } }"
                  class="flex flex-grow-1"
                  ><strong>{{ item.email }}</strong>
                </router-link>
                <div class="subtitle">{{ item.subject }}</div>
              </v-toolbar-title>
            </v-toolbar>
          </div>
        </div>
      </v-col>
      <v-col cols="12" lg="6">
        <div class="list-header list-header--shifts">
          <h4>Upcoming Shifts</h4>
        </div>
        <div v-if="shiftsListVisible" class="mb-12">
          <v-toolbar
            flat
            class="search-result"
            style="margin-bottom: 20px"
            v-for="item in getUpcomingShifts(volunteer_shifts)"
            :key="item.id"
          >
            <volunteer-shift-toolbar :item="item" />
          </v-toolbar>
        </div>

        <div class="list-header list-header--shifts">
          <h4>Past Shifts</h4>
        </div>
        <div v-if="shiftsListVisible">
          <v-toolbar
            flat
            class="search-result"
            style="margin-bottom: 10px"
            v-for="item in getPastShifts(volunteer_shifts)"
            :key="item.id"
          >
            <volunteer-shift-toolbar :item="item" />
          </v-toolbar>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import VolunteerShiftToolbar from "@/components/Volunteer/ShiftToolbar.vue";
export default {
  name: "Tabs",

  props: {
    emails: Array,
    volunteer_shifts: Array,
  },

  components: {
    VolunteerShiftToolbar,
  },

  data() {
    return {
      tooltip: false,
      emailListVisible: true,
      shiftsListVisible: true,
    };
  },

  methods: {
    getFilteredShifts(volunteer_shifts) {
      return volunteer_shifts.filter(
        (volunteer_shift) =>
          volunteer_shift.shift && volunteer_shift.shift.event_id != null
      );
    },

    getUpcomingShifts(volunteer_shifts) {
      return volunteer_shifts.filter(
        (volunteer_shift) =>
          volunteer_shift.shift && volunteer_shift.shift.end_date >= moment().format('YYYY-MM-DD')
      );
    },

    getPastShifts(volunteer_shifts) {
      return volunteer_shifts.filter(
        (volunteer_shift) =>
          volunteer_shift.shift && volunteer_shift.shift.end_date < moment().format('YYYY-MM-DD')
      );
    },

    convertTime(input) {
      return moment(input, "HH:mm:ss").format("h:mm A");
    },
  },
};
</script>

<style scoped>
.tab {
  height: 95px !important;
  display: flex;
  align-items: center;
}
.tab-emails {
  background-color: #dc3036;
  border: 1px solid #dc3036 !important;
}
.tab-shifts {
  background-color: #2196f3;
  border: 1px solid #2196f3 !important;
}
.tab-active .card-text {
  color: #fff !important;
}
.tab-not-active {
  background-color: transparent;
}
.card-text {
  font-size: 1.2rem;
}
.list-header {
  padding: 1rem;
  margin-bottom: 1rem;
  color: #fff;
  font-weight: 700;
}
.list-header--emails {
  background: #dc3036;
}
.list-header--shifts {
  background: #2196f3;
}
</style>
